<template>
  <div class="main">
    <div class="left-table-container">
      <div class="top-info">
        <Title :title="'报告管理'"></Title>
      </div>
      <!-- <div class="search-box">
        <div class="search-conditions">
          <div class="info">
            <span class="lable">报告名称</span>

            <el-input v-model="name" style="width: 242px" placeholder="请输入报告名称"></el-input>
          </div>
        </div>
        <div>
          <div class="">
            <el-button class="search-btn" @click="getList()">查询</el-button>
            <el-button class="btn-reset" @click="reset()">重置</el-button>
          </div>
        </div>
      </div> -->
      <div class="add-btn" style="padding-top: 24px">
        <el-button class="add" icon="el-icon-plus" @click="addNew()">新增</el-button>
      </div>
      <div class="body-content-box">
        <el-table
          :data="reportData"
          default-expand-all
          header-cell-class-name="table-header-cls"
          row-class-name="table-cell-cls"
          max-height="620"
        >
          <el-table-column prop="uploadFile.name" label="报告名称(文件名)"></el-table-column>
          <el-table-column prop="customName" label="展示名"></el-table-column>

          <el-table-column prop="busType" label="类别">
            <template v-slot="scope"> {{ formatterBusType(scope.row.busType) }}</template>
          </el-table-column>
          <el-table-column prop="createTime" label="创建时间">
            <template v-slot="scope">
              <div>{{ formatDate(scope.row.createTime) }}</div>
            </template>
          </el-table-column>

          <el-table-column label="操作" width="180">
            <template slot-scope="scope">
              <el-button class="operation-btn" type="text" @click="handleShow(scope.row)">查看 </el-button>
              <el-button class="operation-btn" type="text" @click="handleUpdate(scope.row)">编辑</el-button>
              <el-button class="btn-del" type="text" @click="handleDelete(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-box">
          <el-pagination
            :current-page="queryParams.pageNum"
            background
            :page-sizes="[10, 20, 50, 100]"
            :page-size="queryParams.pageSize"
            :total="total"
            layout="total, prev, pager, next"
            @size-change="handlePageSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="right-info-content">
      <div v-if="!showInfo" class="no-info">
        <img src="../../../assets/img/no-info.png" alt="" srcset="" />
        <div>暂未选择</div>
      </div>
      <div v-if="showInfo" class="top-info">
        <Title :title="showTitle"></Title>
        <el-row :gutter="20" style="margin: 12px 0">
          <el-form
            ref="objForm"
            :model="objInfo"
            :rules="objForm"
            size="small"
            label-width="160px"
            label-position="top"
            :disabled="viewFlag"
          >
            <el-col :span="16">
              <el-form-item label="显示文件名" prop="name">
                <el-input
                  v-model="objInfo.customName"
                  placeholder="请输入展示名称"
                  clearable
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="16">
              <el-form-item label="文件类型" prop="busType">
                <el-select v-model="objInfo.busType" placeholder="请选择文件类型" clearable :style="{ width: '100%' }">
                  <el-option
                    v-for="(item, index) in typeOptions"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                    :disabled="item.disabled"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col v-if="objInfo.busType == 'report'" :span="16">
              <el-form-item label="所属项目" prop="proId">
                <el-select
                  v-model="objInfo.proId"
                  placeholder="请选择项目"
                  clearable
                  :style="{ width: '100%' }"
                  @change="loadScheme(objInfo.proId)"
                >
                  <el-option
                    v-for="(item, index) in proIdOptions"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                    :disabled="item.disabled"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col v-if="objInfo.busType == 'report'" :span="16">
              <el-form-item label="评估对象" prop="schemeId">
                <el-select v-model="objInfo.schemeId" placeholder="请选择评估对象" clearable :style="{ width: '100%' }">
                  <el-option
                    v-for="(item, index) in schemeOptions"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                    :disabled="item.disabled"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="16">
              <el-form-item label="文件上传">
                <MyUploadFile ref="uploadfile" :query-ext="queryExt" :attachs="objInfo.code" />
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>
        <div class="form-btn-group">
          <el-button v-if="!viewFlag" class="confirm" @click="confirm()">确认</el-button>
          <el-button class="cancel" @click="cancel()">取消</el-button>
        </div>
      </div>
    </div>

    <el-dialog :close-on-click-modal="false" :visible.sync="viewFlag" width="70vw" top="100px" append-to-body>
      <div class="agreement_picture" style="height: 70vh; overflow-y: auto">
        <iframe :src="showFileSrc" frameborder="0" style="width: 70vw; height: 100%"></iframe>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import Title from '@/views/system/title.vue'
  import { ReportApi } from '@/api/report/index'
  import { Scheme } from '@/api/scheme/index.js'
  import { Project } from '@/api/pro/index.js'

  export default {
    name: 'Report',
    components: {
      Title
    },
    props: {},
    data() {
      return {
        load: false,
        showInfo: false,
        viewFlag: false,
        editFlag: false,
        name: null,

        showFileSrc: '',

        reportData: [],
        // 查询参数
        queryParams: {
          pageNum: 1,
          pageSize: 10
        },
        total: 20,

        showTitle: '',

        roleList: [],
        objInfo: {
          customName: undefined,
          busType: undefined,
          proId: undefined,
          schemeId: undefined,
          code: undefined
        },
        queryExt: {},
        objForm: {
          busType: [
            {
              required: true,
              message: '请选择文件类型',
              trigger: 'blur'
            }
          ]
        },
        typeOptions: [
          {
            name: '评价标准',
            id: 'evaluation_city'
          },
          // {
          //   name: '评价标准(省级)',
          //   id: 'evaluation_province'
          // },
          {
            name: '技术路线',
            id: 'line_road'
          },
          {
            name: '行业标准',
            id: 'industry_standard'
          },
          {
            name: '评估报告',
            id: 'report'
          }
        ],
        proIdOptions: [],
        schemeOptions: []
      }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
      this.getList()
      this.loadPro()
    },
    methods: {
      loadPro() {
        let param = {
          pageSize: 100
        }
        Project.getList(param).then((res) => {
          this.proIdOptions = res.data.data.list
        })
      },
      /**
       * @description 选择项目之后获取项目下的方案列表
       * @author hu_ty
       * @since
       * @param {number} id 选择的项目id
       *
       */
      loadScheme(id) {
        let param = {
          pageSize: 100,
          proId: id
        }
        this.schemeOptions = []
        Scheme.getListByProId(param).then((res) => {
          this.schemeOptions = res.data.data.list
        })
      },
      /**
       * @description  获取用户列表
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      getList() {
        let params = {
          pageNum: this.queryParams.pageNum,
          pageSize: this.queryParams.pageSize,
          name: this.name ?? '',
          tel: this.tel ?? ''
        }
        const loading = this.$loading({
          lock: true,
          text: '数据加载...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        ReportApi.report_list(params).then((res) => {
          this.reportData = res.data.data.list
          this.total = res.data.data.total
          loading.close()
        })
      },
      formatterBusType(val) {
        const info = this.typeOptions.find((type) => {
          return type.id === val
        })
        if (info) {
          return info.name
        }
      },
      /**
       * @description 重置
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      reset() {
        this.name = null
        this.tel = null
        this.getList()
      },
      /**
       * @description
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      addNew() {
        this.showInfo = false
        this.viewFlag = false
        this.editFlag = false
        this.showTitle = '上传报告'
        this.objInfo = {}
        this.showInfo = true
      },
      /**
       * @description  新增信息
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      confirm() {
        this.$refs['objForm'].validate((valid) => {
          if (valid) {
            this.objInfo.code = this.$refs.uploadfile.getAttachs()

            if (this.objInfo.id != undefined) {
              ReportApi.report_save(this.objInfo).then((response) => {
                if (response.data.code === 0) {
                  this.$message('修改成功')
                  this.showInfo = false
                  this.editFlag = false
                  this.getList()
                }
              })
            } else {
              ReportApi.report_save(this.objInfo).then((response) => {
                if (response.data.code === 0) {
                  this.$message('新增成功')
                  this.showInfo = false
                  this.getList()
                }
              })
            }
          }
        })
      },
      /**
       * @description
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      cancel() {
        this.editFlag = false
        this.showInfo = false
      },
      handleShow(row) {
        this.viewFlag = true
        this.showFileSrc = row.completeUrl
      },
      /**
       * @description  编辑
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      handleUpdate(row) {
        this.showInfo = false
        this.objInfo = {
          id: row.id,
          customName: row.customName,
          busType: row.busType,
          proId: row.proId,
          schemeId: row.schemeId,
          code: row.code
        }
        this.showTitle = '报告编辑'
        this.queryExt = {
          [row.code]: row.uploadFile
        }
        this.editFlag = true
        this.showInfo = true
      },
      /** 删除按钮操作 */
      handleDelete(row) {
        this.$confirm('是否确认删除文件名为"' + row.uploadFile?.name ? row.uploadFile?.name : '' + '"的文件?', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(function () {
            return ReportApi.report_del(row.id)
          })
          .then(() => {
            this.getList()
            this.$message('删除成功')
          })
          .catch(function () {})
      },
      //分页时展示页面数据的容量
      handlePageSizeChange(val) {
        this.queryParams.pageSize = val
        this.getList()
      },
      //分页时切换页
      handleCurrentChange(val) {
        this.queryParams.pageNum = val
        this.getList()
      }
    }
  }
</script>
<style lang="less" scoped>
  .main {
    height: 100%;
    display: flex;
    gap: 12px;
  }
  .left-table-container {
    // float: left;
    width: 1248px;
    margin-top: 34px;
    margin-left: 24px;
    height: calc(100% - 58px);
    border-radius: 2px;
    background: rgba(255, 255, 255, 1);
    .top-info {
      margin: 0 auto;
      width: calc(100% - 48px);
    }
    .search-box {
      margin: 24px auto 0;
      top: 180px;
      left: 48px;
      width: calc(100% - 48px);
      height: 52px;
      // background-color: red;
      display: grid;
      grid-template-columns: 6.5fr 1fr;
      .search-conditions {
        display: grid;
        grid-template-columns: repeat(3, 33.3%);
        .info {
          height: 32px;
          line-height: 32px;
          .lable {
            margin-left: 22px;
            margin-right: 16px;
            color: rgba(0, 0, 0, 0.9);
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
          }
        }
      }
    }
    .add-btn {
      margin: 24px auto;
      width: calc(100% - 48px);
    }
    .body-content-box {
      margin: 0 auto;
      width: calc(100% - 48px);
      max-height: 660px;
      overflow: auto;
    }
  }
  .right-info-content {
    // float: right;
    margin-top: 34px;
    margin-right: 24px;
    width: 600px;
    height: calc(100% - 58px);
    background: rgba(255, 255, 255, 1);
    position: relative;
    .no-info {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      color: rgba(0, 0, 0, 0.6);
      font-size: 14px;
      line-height: 22px;
    }
    .top-info {
      margin: 0 auto;
      width: calc(100% - 48px);
    }
    .form-btn-group {
      margin: auto;
      width: 552px;
      height: 80px;
      border-top: 1px solid rgba(229, 230, 235, 1);
      line-height: 80px;
      .confirm {
        width: 65px;
        height: 32px;
        border-radius: 2px;
        background: rgba(64, 158, 255, 1);
        align-items: center;
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        padding: 8px 16px !important;
        letter-spacing: 2px;
      }
      .cancel {
        width: 65px;
        height: 32px;
        border-radius: 2px;
        border: 1px solid rgba(229, 230, 235, 1);
        align-items: center;
        font-size: 14px;
        padding: 8px 16px !important;
        letter-spacing: 2px;
        color: rgba(0, 0, 0, 0.9);
      }
    }
  }
  .search-btn {
    width: 65px;
    height: 32px;
    color: #fff;
    background-color: rgba(64, 158, 255, 1);
    border-color: rgba(64, 158, 255, 1);
    border-radius: 2px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 2px;
    padding: 8px 16px !important;
  }
  .btn-reset {
    color: rgba(64, 158, 255, 1);
    background-color: #fff;
    border: 1px solid rgba(64, 158, 255, 1);
    border-radius: 2px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 2px;
    padding: 8px 16px !important;
  }
  .add {
    width: 85px;
    color: #fff;
    background-color: rgba(64, 158, 255, 1);
    border-color: rgba(64, 158, 255, 1);
    border-radius: 2px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 2px;
    padding: 8px 16px !important;
  }
  // 删除按钮
  .btn-del {
    color: #f52f3e;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    // padding:8px 16px;
  }
  /deep/ .el-input__inner {
    height: 32px;
    line-height: 32px;
  }
  // 表格标题
  .table-header-cls {
    background: #f7f8fa !important;
    font-style: normal;
    font-weight: blod !important;
    font-size: 14px;
    height: 55px;
    color: #1d2129;
    text-align: left;
  }

  // 表格行数据
  .table-cell-cls {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4e5969;
    text-align: left;
    height: 55px;
  }
  // 页面分页条
  .pagination-box {
    padding-top: 4px;
    display: flex;
    justify-content: flex-end;
  }
  .el-form-item {
    color: rgba(0, 0, 0, 0.9);
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }
</style>
