import request from '@/utils/request'
const BASE_URL = '/wjhhjk'
const PROVINCE_BASE_URL = '/jssxfhh'

export class ReportApi {
  /**
   * @description  获取报告列表（全部）
   * @param {*}
   *
   */
  static report_list = (params) => {
    return request.post(`${BASE_URL}/scheme/documentFile/listPage`, params)
  }
  /**
   * @description   上传报告信息
   * @param {*}
   *
   */
  static report_save = (params) => {
    return request.post(`${BASE_URL}/scheme/documentFile/save`, params)
  }
  /**
   * @description 删除报告
   * @param {*}
   *
   */
  static report_del = (id) => {
    return request.get(`${BASE_URL}/scheme/documentFile/deleteById/` + id)
  }
}

export class ReportApiPro {
  /**
   * @description  获取报告列表（全部）
   * @param {*}
   *
   */
  static report_list = (params) => {
    return request.post(`${PROVINCE_BASE_URL}/scheme/documentFile/listPage`, params)
  }
  /**
   * @description   上传报告信息
   * @param {*}
   *
   */
  static report_save = (params) => {
    return request.post(`${PROVINCE_BASE_URL}/scheme/documentFile/save`, params)
  }
  /**
   * @description 删除报告
   * @param {*}
   *
   */
  static report_del = (id) => {
    return request.get(`${PROVINCE_BASE_URL}/scheme/documentFile/deleteById/` + id)
  }
}
